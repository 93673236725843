import React from 'react';
import SEO from "../components/seo/Seo";
import ServicesPricing from "../components/pricing/ServicesPricing";
import Header from "../components/headers/Header";
import Footer from "../components/layouts/Footer";
import PricingHeader from "../components/pricing/PricingHeader";

function Cenik(props) {
    const schema = {

    };
    return (
        <>
            <SEO
                title="Cenik obiskov"
                description="Cene obiskov Rudnika Sitarjevec. Potrebno predhodno naročilo. Skupine možno naročiti izven navedenih ur.Otroci do 3. leta brezplačno. Oglede znotraj urnika rednih ogledov organiziramo za skupine 4 oseb ali več, izven urnika rednih ogledov za skupine 6 oseb ali več. "
            />
            <Header />
            <PricingHeader/>
            <ServicesPricing/>
            <Footer/>
        </>
    );
}

export default Cenik;