import React, {useState} from 'react';
import { Link } from 'gatsby';

function PriceItem({priceItem, numOfColumns}) {
    const [contactLinkStyle, setContactLinkStyle] = useState({opacity: 0});

    return (
        <tr
            onMouseEnter={(e) => setContactLinkStyle({opacity: 1})}
            onMouseLeave={(e) => setContactLinkStyle({opacity: 0})}
        >
            <td scope="row">{priceItem.title}</td>
            <td>
                <div className="d-flex justify-content-between">
                    <span>
                        {priceItem.price}
                    </span>
                    { numOfColumns === 2 ?
                        <span className="pricing-contact-link d-none d-md-inline-block" style={contactLinkStyle}>
                            <Link to="/kontakt-in-informacije">Stopite v stik!</Link>
                        </span> : ''
                    }
                </div>
            </td>

            { numOfColumns === 3 ?
                <td>
                    <div className="d-flex justify-content-between">
                        <span>
                            {priceItem.group}
                        </span>

                        <span className="pricing-contact-link d-none d-md-inline-block" style={contactLinkStyle}>
                            <Link to="/kontakt-in-informacije">Stopite v stik!</Link>
                        </span>

                    </div>
                </td> : ''
            }
        </tr>
    );
}

export default PriceItem;