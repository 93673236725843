export const transportRowPrices = [
    {
        title: 'Otroci in osnovnošolci',
        price: '4€ na osebo',
        group: '3€ na osebo'
    },
    {
        title: 'Dijaki, študenti in upokojenci',
        price: '7€ na osebo',
        group: '5,5€ na osebo'
    },
    {
        title: 'Odrasli',
        price: '9€ na osebo',
        group: '7€ na osebo'
    },
    {
        title: 'Skupine do 6 odraslih izven urnika obiskov',
        price: '',
        group: '50€ na skupino'
    },
    {
        title: 'Študijske skupine',
        price: '',
        group: '60€ na skupino'
    },
    {
        title: 'Obisk s pripovedovanjem pravljice',
        price: '',
        group: '60€ na skupino'
    },
    {
        title: 'S škratom po Rudniku Sitarjevec',
        price: '',
        group: '35€ na družino'
    },
];

export const mainRowPrices = [
    {
        title: 'Osnovnošolci od 10 do 16 let',
        price: '13€ na osebo',
    },
    {
        title: 'Dijaki, študenti (16-25 let) in upokojenci',
        price: '18€ na osebo',
    },
    {
        title: 'Odrasli',
        price: '20€ na osebo',
    },
];

export const transportRowWithMealPrices = [
    {
        title: 'Otroci in osnovnošolci',
        price: '7,5€ na osebo',
    },
    {
        title: 'Dijaki, študenti in upokojenci',
        price: '11,5€ na osebo',
    },
    {
        title: 'Odrasli',
        price: '13,5€ na osebo',
    },
    {
        title: 'Animirano vodenje (rojstni dan,...)',
        price: '14€ na osebo',
    },
];

export const mainRowWithMealPrices = [
    {
        title: 'Osnovnošolci od 10 do 16 let',
        price: '19€ na osebo',
    },
    {
        title: 'Dijaki, študenti (16-25 let) in upokojenci',
        price: '25€ na osebo',
    },
    {
        title: 'Odrasli',
        price: '27€ na osebo',
    },
];

export const combinationOfRowsPricing = [
    {
        title: 'Osnovnošolci od 10 do 16 let',
        price: '19€ na osebo',
    },
    {
        title: 'Dijaki, študenti (16-25 let) in upokojenci',
        price: '21€ na osebo',
    },
    {
        title: 'Odrasli',
        price: '25€ na osebo',
    },
    {
        title: 'Osnovnošolci od 10 do 16 let z malico',
        price: '22,50€ na osebo',
    },
    {
        title: 'Dijaki, študenti (16-25 let) in upokojenci z malico',
        price: '26,50€ na osebo',
    },
    {
        title: 'Odrasli z malico',
        price: '30€ na osebo',
    },
];